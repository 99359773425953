import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER } from '../../../config-global';
// components
import Logo from './Logo';
import { useSettingsContext } from '../../../components/settings';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import NavBarHorizontal from './NavBarHorizontal';
// ----------------------------------------------------------------------
export default function Header() {
    const theme = useTheme();
    const { themeLayout } = useSettingsContext();
    const isNavHorizontal = themeLayout === 'horizontal';
    const isDesktop = useResponsive('up', 'lg');
    const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
    return (_jsx(AppBar, { sx: {
            boxShadow: 'none',
            height: HEADER.H_MOBILE,
            ...bgBlur({
                color: theme.palette.background.default,
            }),
            transition: theme.transitions.create(['height'], {
                duration: theme.transitions.duration.shorter,
            }),
            ...(isDesktop && {
                height: HEADER.H_DASHBOARD_DESKTOP,
                ...(isOffset && {
                    py: 0.5,
                }),
                ...(isNavHorizontal && {
                    width: 1,
                    bgcolor: 'background.default',
                    height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                }),
            }),
            position: 'fixed',
            zIndex: 999,
        }, children: _jsx(Toolbar, { sx: {
                height: 1,
                px: { lg: 2 },
            }, children: _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", flex: 1, spacing: 2, children: [_jsxs(Stack, { direction: "row", alignItems: "center", sx: {
                            borderRadius: 1.2,
                            background: '#F1F7FF',
                            gap: 5,
                            paddingRight: 2,
                            maxWidth: '80vw',
                        }, children: [_jsx(Logo, {}), _jsx(NavBarHorizontal, {})] }), _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", spacing: 2, children: [_jsx(NotificationsPopover, {}), _jsx(AccountPopover, {})] })] }) }) }));
}
